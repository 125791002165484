import { Button, Col, DatePicker, Form, Input, Row, Select, TimePicker } from "antd"
import { ArchivedTable } from "./archivedTable"
import { useRecoilState } from "recoil"
import { archivedSearchValuesState } from "./recoil.state"
import { ArchivedDetailDrawer } from "./archivedDetailDrawer"
import { useEffect, useState } from "react"
import { roleState } from "../stateManage"
import axios from "axios"
import { archivedItemList } from "./api"
import _ from 'lodash'
export const ArchivedPage = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [archivedSearchValues, setArchivedSearchValues] = useRecoilState(archivedSearchValuesState)
    const [shippers, setShippers] = useState([])
    const [containerIds, setContainerIds] = useState([])
    const [customers, setCustomers] = useState([])
    const [bills, setBills] = useState([])
    const [instructions, setInstructions] = useState([])
    const [shipperVias, setShipperVias] = useState([])
    const [POs, setPOs] = useState([])

    const [form] = Form.useForm();

    const onFinish = (values) => {
        values.loadedQTY = Number(values.loadedQTY)
        var v = _.omitBy(values, v=>v==undefined || v==null || v=='')
        setArchivedSearchValues(v)
    }
    useEffect(() => {
        axios.get(archivedItemList).then(res => {
            setContainerIds(res.data.containerIds.map(it => ({ label: it, value: it })))
            setCustomers(res.data.customers.map(it => ({ label: it, value: it })))
            setBills(res.data.bills.map(it => ({ label: it, value: it })))
            setInstructions(res.data.instructions.map(it => ({ label: it, value: it })))
            setShipperVias(res.data.shipperVias.map(it => ({ label: it, value: it })))
            setPOs(res.data.POs.map(it => ({ label: it, value: it })))
            if (role == 'admin') {
                setShippers(res.data.shippers.map(it => ({ label: it, value: it })))
            }
        }).catch(e => {

        })
        if (Object.keys(archivedSearchValues).length > 0) {
            form.setFieldsValue(archivedSearchValues)
        }
    }, [])
    const resetClick = () => {
        setArchivedSearchValues({})
    }
    var colSpan = 5
    const adminSearchOptions = ['shipper', 'instruction','shipperVia','containerId', 'billNumber', 'deliveryMethod', 'customer', 'PO', 'loadedQTY', 'deliveryDate', 'deliveryTime', 'location',"devannedDate"]
    var searchOptions = []
    if (role == 'admin') {
        searchOptions = adminSearchOptions
    }else if(role == 'user'){
        _.remove(adminSearchOptions, (it) => it =='shipper' || it=='location' || it=='deliveryMethod')
    }else if(role == 'customer'){
        _.remove(adminSearchOptions, (it) => it=='shipper' || it=='location' || it=='customer' || it=='deliveryMethod')
    }else{
        searchOptions = []
    }
    searchOptions=adminSearchOptions
    return <>
        <Form
            labelCol={{ span: 10 }}
            // wrapperCol={{ span: 20 }}
            labelAlign="right"
            name="search"
            onFinish={onFinish}
            form={form}
            >
            <Row>
                <Col span={searchOptions.includes('shipper') ? colSpan : 0}  >
                    <Form.Item name="shipper" label="Forwarder">
                        <Select showSearch allowClear={true} options={shippers} />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('containerId') ? colSpan : 0} >
                    <Form.Item name="containerId" label="CNTR/AWB#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={containerIds}
                        />
                    </Form.Item>
                </Col>

                <Col span={searchOptions.includes('instruction') ? colSpan : 0}>
                    <Form.Item name="instruction" label="Instruction">
                        <Select
                            showSearch
                            allowClear={true}
                            options={instructions}
                        />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('shipperVia') ? colSpan : 0}>
                    <Form.Item name="shipperVia" label="shipperVia">
                        <Select
                            showSearch
                            allowClear={true}
                            options={shipperVias}
                        />
                    </Form.Item>
                </Col>

                <Col span={searchOptions.includes('billNumber') ? colSpan : 0} >
                    <Form.Item name="billNumber" label="Bill#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={bills}
                        />
                    </Form.Item>
                </Col>

                <Col span={searchOptions.includes('customer') ? colSpan : 0} >
                    <Form.Item name="customer" label="Customer">
                        <Select
                            showSearch
                            allowClear={true}
                            options={customers}
                            mode='multiple'
                        />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('PO') ? colSpan : 0}>
                    <Form.Item name="PO" label="PO#">
                        <Select
                            showSearch
                            allowClear={true}
                            options={POs}
                        />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('loadedQTY') ? colSpan : 0}>
                    <Form.Item name="loadedQTY" label="Loaded Q’ty">
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('ETD') ? colSpan : 0}>
                    <Form.Item name="ETD" label="ETD">
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('ETA') ? colSpan : 0}>
                    <Form.Item name="ETA" label="ETA">
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('deliveryDate') ? colSpan : 0}>
                    <Form.Item name="deliveryDate" label="Est. Del. Date" >
                        <DatePicker format={'YYYY-MM-DD'} />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('deliveryTime') ? colSpan : 0} >
                    <Form.Item name="deliveryTime" label="Est. Del. Time">
                        <TimePicker format={'HH:mm'} minuteStep={10} changeOnBlur={true} showNow={false} />
                    </Form.Item>
                </Col>
                <Col span={searchOptions.includes('devannedDate') ? colSpan : 0}>
                    <Form.Item name="devannedDate" label="Dev. Date">
                        <DatePicker />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="end" style={{ margin: '10px', marginRight: 0 }}>
                <Col>
                    <Form.Item style={{ marginRight: '20px' }} >
                        <Button type="primary" htmlType="submit">Search</Button >
                    </Form.Item>
                </Col>
                <Col >
                    <Form.Item   >
                        <Button onClick={resetClick} type="primary" htmlType="reset">Reset</Button >
                    </Form.Item>
                </Col>
            </Row>
        </Form>

        <ArchivedTable />
        <ArchivedDetailDrawer />
    </>
}