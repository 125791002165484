
import { ScheduleTwoTone } from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { roleState } from '../stateManage';
import { ContainerAction, containerTableDataType } from './type';
import { Popover, Typography } from 'antd';
import { ActionCell } from './components/actionCell';
import { containerDetailDrawerIsOpenState, detailEditModelIsOpenState, focusContainerDetailRecordState } from './recoil.state';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import _ from 'lodash'

export const usePageTableColumn = () => {
    const [role, setRole] = useRecoilState(roleState)
    const columnDefaultWidth = 150
    var columnsList = [['Operator', 'operator'], ['Bill#', 'billNumber'], ['CNTR/AWB#', 'containerId'],
    ['Shipment Q’ty', 'shipmentQTY', 160], ['Instruction', 'instruction', 180], ['ETD', 'ETD'], ['ETA', 'ETA'], ['Shipment Notes', 'chinaNotes', 150]]

    if (role == 'admin') {
        columnsList.unshift(['Forwarder', 'shipper'])
    }
    let mergedColumns = columnsList.map((it, i) => {
        const width = it[2] ? it[2] : columnDefaultWidth

        return {
            title: it[0],
            dataIndex: it[1],
            key: i,
            width: role == 'admin' ? width : null,
            // sorter: true,
            onCell: (record: containerTableDataType) => ({
                record,
                title: it[0],
                dataindex: it[1],
            }),
            render: (text, record, index) => {
                if (!text && text !== 0) {
                    return '-'
                }
                if(it[1]=='ETD'||it[1]=='ETA'){
                    return text.slice(0, 10)
                }
                if (String(it[0]).includes('Note')) {
                    return <Popover style={{ color: 'green' }} content={<p style={{ width: '400px' }}>{text}</p>} title="Notes">
                        <ScheduleTwoTone />
                    </Popover>
                }
                return text

            }
        }
    }) as any

    mergedColumns.push({
        title: 'Action',
        dataIndex: 'action',
        key: -1,
        fixed: 'right',
        with: role == 'admin' ? 200 : null,
        onCell: (record: containerTableDataType) => ({
            record,
            title: 'Action',
            dataindex: 'action',
        }),
        render: (_: any, record: containerTableDataType) => {
            return <ActionCell record={record} />
        },
    })
    // 找到ETD列
    var ETDColumn = mergedColumns.find(it => it.dataIndex == 'ETD')
    // 日期排序ETD
    ETDColumn.sorter = (a, b) => {
        return new Date(a.ETD).getTime() - new Date(b.ETD).getTime()
    }
    // 找到ETA列
    var ETAColumn = mergedColumns.find(it => it.dataIndex == 'ETA')
    // 日期排序ETA
    ETAColumn.sorter = (a, b) => {
        return new Date(a.ETA).getTime() - new Date(b.ETA).getTime()
    }
    return mergedColumns
}

export const usePageDetailTableColumn = (focusContainerRecord) => {
    const [containerDetailDrawerIsOpen, setContainerDetailDrawerIsOpen] = useRecoilState(containerDetailDrawerIsOpenState)
    const [focusDetailRecord, setFocusDetailRecord] = useRecoilState(focusContainerDetailRecordState)
    const [detailEditModelIsOpen,setDetailEditModelIsOpen] = useRecoilState(detailEditModelIsOpenState)

    const [detailColumns, setDetailColumns] = useState([])
    const editClick = (record) => {
        setDetailEditModelIsOpen(true)
        setFocusDetailRecord(record)
    }
    var columns = [['Customer', 'customer'], ['PO#', 'PO'], ['Loaded Q’ty', 'loadedQTY'], ['Total Q’ty', 'totalQTY'], ['Est. Del. Date', 'deliveryDate'],
    ['Est. Del. Time', 'deliveryTime'], ['China Notes', 'PO_chinaNotes'], ['US Notes', 'PO_US_Notes']
    ] as any
    const role = localStorage.getItem('role')

    if (role == 'customer') {
        // _.remove(columns, it => it[1] == 'location')
        _.remove(columns, it => it[1] == 'customer')
    }
    columns = columns.map((it, i) => {
        return {
            title: it[0],
            dataIndex: it[1],
            key: i,
            // width: width,
            onCell: (record) => ({
                record,
                title: it[0],
                dataindex: it[1],
            }),
            render: (text, record, index) => {
                if (!text && text !== 0) {
                    return <>-</>
                }
                if (it[0] == 'Est. Del. Date') {
                    return dayjs(text).format('YYYY-MM-DD')
                }
                if (it[0] == 'Est. Del. Time') {
                    return text.slice(0, 5)
                }
                if (String(it[0]).includes('Note')) {
                    return <Popover style={{ color: 'green' }} content={<p style={{ width: '400px' }}>{text}</p>} title="Notes">
                        <ScheduleTwoTone />
                    </Popover>
                }
                return <>{text}</>
            }
        }
    })

    columns.push({
        title: 'Action',
        dataIndex: 'action',
        key: -1,
        with: 100,
        onCell: (record) => ({
            record,
            title: 'Action',
            dataindex: 'action',
        }),
        render: (_: any, record) => {
            return <Typography.Link style={{ marginRight: '10px' }} onClick={e => editClick(record)}>
                Edit
            </Typography.Link>
        },
    })
    useEffect(() => {
        if(Object.keys(focusContainerRecord).length==0){
            return
        }
        var POs = JSON.parse(JSON.stringify(focusContainerRecord.POs))
        POs = POs.sort((a, b) => a.customer.localeCompare(b.customer))
        var customer = columns.find(it => it.dataIndex == 'customer')
        if (customer) {
            customer!.onCell = (record, rowIndex) => {
                const obj: any = { rowSpan: 0 }
                const occurcount = POs.filter((data) => data.customer == record.customer).length;
                rowIndex = rowIndex ? rowIndex : 0
                try{
                    if (rowIndex == 0 || POs[rowIndex - 1].customer != record.customer) {
                        obj.rowSpan = occurcount
                    }
                }catch(e){
                    console.log('date not match')
                }
                
                return obj
            }
        }
     
        setDetailColumns(columns)
    }, [containerDetailDrawerIsOpen])

    return detailColumns
}