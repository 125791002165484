import { useRecoilState } from "recoil"
import { roleState } from "../stateManage"
import { Popover, Upload, UploadProps, message, Image, Space } from "antd"
import { ScheduleTwoTone, DownloadOutlined,ZoomOutOutlined ,ZoomInOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import { apiHost } from "../apiInfo";
import { downloadPODUrl, uploadPODUrl } from "./api";
import { deliveryPageActionListState } from "./recoil.state";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import _ from 'lodash'

export const useDeliveryColumn = () => {
    const [role, setRole] = useRecoilState(roleState)
    const [deliveryPageActionList, setDeliveryPageActionList] = useRecoilState(deliveryPageActionListState)
    const [visible, setVisible] = useState(false);
    const [scaleStep, setScaleStep] = useState(0.5);
    const [podLink, setPodLink] = useState('');
    const navigate = useNavigate()

    const columnDefaultWidth = 150
    //['POD', 'podImageId']
    let columnsT = [['Forwarder', 'shipper'],['Bill#', 'billNumber'], ['CNTR/AWB#', 'containerId'],
    ['Customer', 'customer'], ['PO#', 'PO'], ['Del. Q’ty', 'loadedQTY', 125], ['Pallets', 'pallets', 120],
    ['US Note', 'PO_US_Notes', 110], ['Del. Date', 'deliveryDate'], ['Del. Time', 'deliveryTime'],['Location', 'location', 100], ['Del. Method', 'deliveryMethod']]

    if (role == 'user') {
        _.remove(columnsT, it => it[1] == 'shipper')
        _.remove(columnsT, it => it[1] == 'location')
        _.remove(columnsT, it => it[1] == 'deliveryMethod')
    }
    if (role == 'customer') {
        _.remove(columnsT, it => it[1] == 'shipper')
        _.remove(columnsT, it => it[1] == 'location')
        _.remove(columnsT, it => it[1] == 'deliveryMethod')
        _.remove(columnsT, it => it[1] == 'customer')
    }
    let columns = columnsT.map((it, i) => {
        const width = it[2] ? it[2] : columnDefaultWidth
        return {
            title: it[0],
            dataIndex: it[1],
            key: i,
            width: role == 'admin' ? width : null,
            render: (text, record, index) => {
                if (!text && text !== 0 && it[1] != 'podImageId') {
                    return <>-</>
                }

                if (it[1] == 'deliveryDate') {
                    return dayjs(text).format('YYYY-MM-DD')
                }
                if (String(it[0]).includes('Note')) {
                    return <Popover style={{ color: 'green' }} content={<p style={{ width: '400px' }}>{text}</p>} title="Notes">
                        <ScheduleTwoTone />
                    </Popover>
                }
                if (it[1] == 'podImageId') {

                    const importXlsxProps: UploadProps = {
                        name: 'podimage',
                        accept: '.jpg,.jpeg,.png',
                        data: { _id: record._id },
                        withCredentials: true,
                        action: apiHost + uploadPODUrl, // 后端上传接口地址
                        onChange(info) {
                            if (info.file.status !== 'uploading') {
                                // message.info(info.file, info.fileList);
                            }
                            if (info.file.status === 'done') {
                                message.success(`${info.file.name} file uploaded successfully`);
                                //更新表格
                                var deliveredPageActions = JSON.parse(JSON.stringify(deliveryPageActionList));
                                deliveredPageActions.push('uploadPOD')
                                setDeliveryPageActionList(deliveredPageActions)
                            }else if (info.file.status === 'error') {
                                if(info.file.response.statusCode === 401){
                                    navigate('/')
                                }
                                message.error(`${info.file.name} file upload failed.`);
                            }
                        },
                    };

                    if (text) {
                        const src = apiHost + downloadPODUrl + record.podImageId
                        const onDownload = () => {
                            fetch(src)
                              .then((response) => response.blob())
                              .then((blob) => {
                                const url = URL.createObjectURL(new Blob([blob]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.download = 'image.png';
                                document.body.appendChild(link);
                                link.click();
                                URL.revokeObjectURL(url);
                                link.remove();
                              });
                          };
                        return <Image
                            crossOrigin="use-credentials"
                            preview={{
                                imageRender: () => (
                                    <Image crossOrigin="use-credentials" src={src} />
                                ),
                                toolbarRender: (
                                    _,
                                    {
                                      transform: { scale },
                                      actions: { onZoomOut, onZoomIn },
                                    },
                                  ) => (
                                    <Space size={12} className="toolbar-wrapper">
                                      <DownloadOutlined onClick={onDownload} />
                                      {/* <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} /> */}
                                    </Space>
                                  ),
                            }}
                            width={25}
                            height={25}
                            src={src}
                        />

                    } else {
                        return <Upload {...importXlsxProps}>
                            <a >Upload Picture</a>
                        </Upload>
                    }
                }
                
                return <>{text}</>
            }
        }
    }) as any


    // 日期排序
    columns.find(it => it.dataIndex == 'deliveryDate').sorter = (a, b) => {
        return new Date(a.deliveryDate).getTime() - new Date(b.deliveryDate).getTime()
    }
    // columns.find(it => it.dataIndex == 'podImageId').fixed = 'right'
    return columns
}